import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { PortfolioChart, PortfolioMetrics } from "../../../components/widgets/portfolio-ref";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/leveraged-etf-portfolios/"
          }}>{`Leveraged ETF Portfolios`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/volatility-targeting/"
          }}>{`Volatility Targeting`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-tax/"
          }}>{`TT's All-Stars Tax-Efficient`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-four-fifteen/"
          }}>{`TT's Four-Fifteen`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`TT's VIX Spritz`}</a></li>
      </ul>
    </Aside>
    <p>{`Investing is a means to achieve financial goals. For most people, the objective is to accumulate wealth and to retire comfortably. Consequently, a large part of investing happens in tax-deferred accounts, e.g., a 401(k). However, depending on your personal situation and employment status, parts of your investments might still reside in taxable accounts. This situation leads to an important question: How can we invest in a tax-efficient manner?`}</p>
    <div css={`height:0;clear:both;`} />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c05ea3fbec8110a65f6c3de81eb28a22/c1b63/bald-eagle.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAC4jAAAuIwF4pT92AAAB1klEQVQoz6WSy2sTYRTFR/wLxIU7N65c+W+IgmtXLai4KNSVi7oV0UUWdVmkSmuTKE6gpUlt0ocWGmwRlKRpUievpnnMJCaSMRNjJ/P4yUwyJt3qgcMH5+Oe7557P4EhbNt26cAwbUzT9q4wrNGdc1q2jelojCB/PyWV1RDGDa1h0Waqww1fkccrDaot3dUsa2DikFERvZ5BrtTlzXuF+Nf2WUOvC6XdZ2KujHA7yeWpDOKnNuPo/jLYTqj4xBozr06YX5YJhuvsJ9WB4biZ16WDlc8q12YkhMkU0y8rvP3Q5O58lSsPs5ybPESYSHL9iUQgWiO4WWAvU0c4M5uxOO6ELIt4QuXe7DEX7qe5OJXm/J0DLk0nuOU74lkoS2CrQOhjCf9Ggb2kG3nUnWdca+jEv6i8izYQYzKP5vJcfZDh5lOJF+sSwViJyI7M6nad0IaCGKuPInteet8ineuyvttkIXzC4tox/mjRjbIYKeN7XeZ5oIwYVQhGFJZWFZbCMv6wTHBNZmG5NljKqaHT0jSqjR67KZn9I4VMpUm63CRd+MlhTnMfkopdvhU1UjltoOUdfci8xoHUoVL/PViK2u/Q0n8AJv8LwdusaVvOOP/+x3+hU/sHZl3SPGPr6DUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Tax-Efficient Investing",
            "title": "TuringTrader.com: Tax-Efficient Investing",
            "src": "/static/c05ea3fbec8110a65f6c3de81eb28a22/c1b63/bald-eagle.png",
            "srcSet": ["/static/c05ea3fbec8110a65f6c3de81eb28a22/5a46d/bald-eagle.png 300w", "/static/c05ea3fbec8110a65f6c3de81eb28a22/0a47e/bald-eagle.png 600w", "/static/c05ea3fbec8110a65f6c3de81eb28a22/c1b63/bald-eagle.png 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Buy and Hold`}</h2>
    <p>{`The first and most obvious answer to the question is buy and hold. As you are browsing `}<em parentName="p">{`TuringTrader.com`}</em>{`, you are probably not looking to do that. However, it is essential to understand this case to use it as a benchmark later.`}</p>
    <p>{`We look at a vanilla 60/40 portfolio for this article's purpose, holding 60% of an S&P 500 ETF and 40% of an ETF tracking the aggregate bond market. Contrary to popular belief, this passive portfolio is not totally tax-deferred. Let's have a closer look at its taxation.`}</p>
    <h3>{`Stocks`}</h3>
    <p>{`When it comes to stock investments' taxation, capital gains are the most important aspect to consider. Capital gains account for the majority of stock profits, and taxes on these gains are due when investors liquidate their positions. The tax rate depends on the duration investors held their assets.`}</p>
    <p>{`For investment periods of 1 year or less, short-term treatment applies, which results in the same tax rate as the investor's income tax rate. For investment periods of more than 1 year, long-term treatment applies, which results in a reduced rate of 0%, 15%, or 20%, depending on the investor's total income.`}</p>
    <p>{`In addition to capital gains, many stocks pay dividends. Especially when investing in broad index ETFs, investors will likely earn some dividends. These dividends are treated very differently from capital gains, as they are subject to taxation the same year they are paid. Even worse, dividends are taxed at the regular income tax rate.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e390626287904c3ef4b5dd98a25a2c38/21b4d/sp500-cap-gains-dividends.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVElEQVQoz51T2W7EIAzM//9g3/JS9dhdyIYQTmNPZbLZI32o1JFGBjuYMY4HYy4yjqM450RE0FqT1lq3uleo2dfM3GPcGoQZwg25kJxsxBqSDEQkO7BBbtxyHbDHWCBLbLh6gnFFPs4eteaeEHg9jSOPyJXhY8XkApyPCCGIsTNU3FBrlT3XXtsxGbNgCYQvE/E9ZfhYUGsFUe2WWaSU7lOF7aEQ+KVI1Zg5wa8Rq1+wuBlUEqQRwA1gAhqBS5JaMgbvPezkUHPuAQiBcsLqA6x1MOaKHDxA+X5YKZ11I5Nos7rCnCKsnXG+TIA0nG3Aya54G0+wVw/m9uj0UyUH9GfKeWtKr9SHLJ/nRT5OV4kpCzPdu/oX9nePMfam9CvM5DC+X/BfaEL9YwZteSlFUkoSb2uVvlP3evOzb/cffZpruE3GC5n5Za2Ht+lgnZCXb56t8gdoFWABO7cILAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: S&P 500 Capital Gains and Dividends",
            "title": "TuringTrader.com: S&P 500 Capital Gains and Dividends",
            "src": "/static/e390626287904c3ef4b5dd98a25a2c38/c1b63/sp500-cap-gains-dividends.png",
            "srcSet": ["/static/e390626287904c3ef4b5dd98a25a2c38/5a46d/sp500-cap-gains-dividends.png 300w", "/static/e390626287904c3ef4b5dd98a25a2c38/0a47e/sp500-cap-gains-dividends.png 600w", "/static/e390626287904c3ef4b5dd98a25a2c38/c1b63/sp500-cap-gains-dividends.png 1200w", "/static/e390626287904c3ef4b5dd98a25a2c38/21b4d/sp500-cap-gains-dividends.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above separates the total return into capital gains and dividends paid. As we can see, capital gains account for most of the profits from stocks, with an annual return of about 7.1% since early 2007. Dividends account for an additional 2.1% per year, resulting in a total return of about 9.4%.`}</p>
    <p>{`So it becomes clear that holding stock investments is a good idea: doing so will ensure that most of the investment profits are tax-deferred. However, almost a quarter of the total profits stem from dividends and subject to immediate taxation.`}</p>
    <h3>{`Bonds`}</h3>
    <p>{`When thinking about bonds, most people focus on the interest income they generate. Tax for interest income is due the same year as earned, and the tax rate is the investor's regular income tax rate.`}</p>
    <p>{`However, many investors forget about another source of profits from bonds: capital gains. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Bond_valuation"
      }}>{`Bond Valuation`}</a>{` dictates that bond prices fluctuate when interest rates change. And this price fluctuation, bonds are subject to capital gains.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cd668cd895f6d27d44f571d66ed7c725/21b4d/AGG-interest-cap-gains.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABR0lEQVQoz5VTa2vDMAzM//+RZbB+2NpSWGJbsvW4ITvJTGGDCQ4/ZF1053i5325+uVz8er06EbmZQVX7eIS77xhzs8grTDXmWFP1z2dBztmX1loUd0QcHIPniLE+9kQdAXOgimMr6k0MTMUXEcEcB8PcXURtFoXIrPjKDZkEKTOoEKDs3ghUyuhw7gaDoH8xkXaSr9RArCiFIbUCwgPaAJVebO4Iy84Oj05CxlYEa2q92KWiURkEJgcBZh/2hsDMvcNTXgk5a28dIQHW4Kanqb9F+M/MKCE5bin8eTwTHo8V1hgqFbVWMFfU1tB2ENG+z30d6gIhdc/5sq2b3z7u/vZ+93XdPDzlWl1ETm/nP2FGnJnuYBCmnLsfuRTM8v8bwRHkC1HxYA79Yeor/srRyzql5Eu8ihk2XsnP2qyTjpz1vE5n5jHwDavDYTqWGT/RAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Aggregate Bond Market Interest and Capital Gains",
            "title": "TuringTrader.com: Aggregate Bond Market Interest and Capital Gains",
            "src": "/static/cd668cd895f6d27d44f571d66ed7c725/c1b63/AGG-interest-cap-gains.png",
            "srcSet": ["/static/cd668cd895f6d27d44f571d66ed7c725/5a46d/AGG-interest-cap-gains.png 300w", "/static/cd668cd895f6d27d44f571d66ed7c725/0a47e/AGG-interest-cap-gains.png 600w", "/static/cd668cd895f6d27d44f571d66ed7c725/c1b63/AGG-interest-cap-gains.png 1200w", "/static/cd668cd895f6d27d44f571d66ed7c725/21b4d/AGG-interest-cap-gains.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart separates the total return into interest paid and capital gains. As we can see, at an average of 3.1%/year, interest payments account for about three-quarters of the profits. The remaining quarter, 1.2% annually, stems from capital gains.`}</p>
    <p>{`It is worth noting that the balance between interest income and capital gains largely depends on the bond's maturity. The aggregate bond market has an average maturity of about 7.8 years. If we switched to U.S. Treasuries with 20+ years remaining maturity, most returns would stem from capital gains.`}</p>
    <p>{`The important takeaway is that for bonds, it does not matter much if we trade them or not. Because the majority of returns stem from immediately taxed interest income, trading activity hardly does any harm.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Income Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tax-Deferred`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Taxable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stock Capital Gains`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.1%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stock Dividends`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.1%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bond Interest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.1%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bond Capital Gains`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.2%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`60/40-weighted Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`4.74%`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`2.5%`}</strong></td>
        </tr>
      </tbody>
    </table>
    <p>{`As a final step, we need to put these returns into the portfolio context. We weigh the returns in the tax-deferred and taxable categories with the asset weights in a naive attempt. This method is only a rough estimate, as it ignores the effects of rebalancing. Nonetheless, it provides some practical guidance: Only about 65% of the returns of a passive 60/40 are tax-deferred.`}</p>
    <p>{`It is further helpful to think about other buy and hold portfolios. As hinted at above, increasing bond maturity will, in periods of falling bond yields, drive up returns and tax-efficiency. However, doing so will also cripple returns in times of rising yields. Further, bond-heavy portfolios, e.g., the `}<a parentName="p" {...{
        "href": "/portfolios/robbins-all-seasons/"
      }}>{`All-Seasons Portfolio`}</a>{`, are significantly less tax-efficient.`}</p>
    <h2>{`Tax-Saving Techniques`}</h2>
    <p>{`We can use the observations we made with the 60/40 portfolio to formulate the key aspects of a tax-efficient strategy. First, we define our goal: We consider a portfolio tax-efficient if more than 50% of its total return qualify for long-term treatment of capital gains.`}</p>
    <p>{`This goal provides some breathing room for tactical asset allocation. As a general concept, we aim to keep a large portion of our capital permanently allocated to U.S. stocks while actively managing the remainder. In times of stress, we use this actively managed tranche to counteract the exposure to the U.S. stock market.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7697449deeb9fd920ae26ecbce0ec543/21b4d/asset-beta.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkklEQVQoz41Q2XLbQAzT//9f3x03bpI6luS9ueQCGa6r1tPpQzmD4bEgBHG53W44v15w/v6Kl5cTfry9Yb++o5y/YQzQVAEVAqDGFVoCrQv79k5NO805IEwqSwxYVBpGWmFpA8gJaxkj7xj3D9rtDLueMMLP2Y/1grG/ccRPIm+cs/sHWO9sOWLp8UZslwfySjhxu0xp5HWKzFlLRNlnPT5P5DB6oEXq9QQzpUjHkmKCKBBSRlqv0LRD4g7VATFQ1aBdqYPsBko3+rzrYC6VooNVDCEmhhixlNoYYmbKlbl1xiKs3bjtgTlX1tYZYmGpMsUmL1fGVFiaTNEqivmBnLG4VYwx7Q8zxnBnDIEijbUUxhhmdvQurLWwizBMjveVpRT4vohg6b0/boE5myG/ZqpKf/c8xmCMka21Q4RmNvd673O5tYZFVfHQ+xNH7/nvN3dRa/399oSH4OHwf8Od+q/+K8QFXbVJg9v22h04jtqzO3IcHD/+M9+z937LxX/DzHDkMcbEc+1kP43HMXvmPO9/AY+CW+LDiB30AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Asset classes with negative beta",
            "title": "TuringTrader.com: Asset classes with negative beta",
            "src": "/static/7697449deeb9fd920ae26ecbce0ec543/c1b63/asset-beta.png",
            "srcSet": ["/static/7697449deeb9fd920ae26ecbce0ec543/5a46d/asset-beta.png 300w", "/static/7697449deeb9fd920ae26ecbce0ec543/0a47e/asset-beta.png 600w", "/static/7697449deeb9fd920ae26ecbce0ec543/c1b63/asset-beta.png 1200w", "/static/7697449deeb9fd920ae26ecbce0ec543/21b4d/asset-beta.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Asset Classes`}</h3>
    <p>{`To work well for hedging, we need assets with a negative correlation to the stock market. The higher their absolute beta, the less capital we need to compensate for the exposure from the assets we intend to hold onto. Unless we venture into options trading, we mainly have the following asset classes at our disposal:`}</p>
    <ul>
      <li parentName="ul">{`leveraged Treasury ETFs`}</li>
      <li parentName="ul">{`inverse-leveraged stock-market ETFs`}</li>
      <li parentName="ul">{`ETPs linked to VIX futures`}</li>
    </ul>
    <p>{`While all three asset classes have negative betas, their role and usefulness differ. Leveraged Treasuries have a relatively low beta. Even triple-leveraged Treasuries with 20+ years of maturity rarely reach a beta of minus two. Further, the beta keeps fluctuating, making it challenging to use Treasuries for hedging purposes. However, because of their positive alpha and lower beta, Treasuries are good candidates for creating positive portfolio returns in bearish markets.`}</p>
    <p>{`Inverse-leveraged ETFs have a defined relationship to their underlying. Consequently, the beta is constant, making them great candidates for hedging and precisely controlling the total exposure. It seems like a good idea to also use inverse ETPs to profit from bearish periods. Before attempting to do so, it is vital to keep in mind that this is just another directional bet, requiring exact timing in periods of high market uncertainty. `}</p>
    <p>{`Lastly, we have ETPs linked to the VIX. These products notably have the highest hedging powers, with a beta between negative four and five. Unfortunately, the beta fluctuates quickly, making it challenging to maintain proper hedge ratios. However, by adding positive exposure to the VIX, we can directly counteract what investors fear the most: volatility. Even better, this hedge is, unless our inverse-leveraged ETFs, non-directional. Further, in times of high market fear, the VIX term structure goes into backwardation, leading to a positive carry for the VIX-linked ETPs.`}</p>
    <h3>{`Portfolio Construction`}</h3>
    <p>{`Now that we have identified the asset classes we want to use for hedging, we are ready to construct our portfolio. During bullish market periods, the choice is trivial: We invest 100% of our assets into U.S. stocks. To detect market regimes, we use our trusted `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{` indicator.`}</p>
    <p>{`When `}<em parentName="p">{`Market Vane`}</em>{` turns negative, we want to reduce our exposure to U.S. stocks and add Treasuries instead. However, we want to do liquidate as little of our stock position as possible. With the passive 60/40 in mind, we chose to trade 40% while holding on to 60% of our stock position.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Asset Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bull Markets`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bear Markets`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S&P 500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3x inverse S&P 500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3x U.S. Treasuries`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Net Exposure`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`100% S&P 500`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`60% U.S. Treasuries`}</strong></td>
        </tr>
      </tbody>
    </table>
    <p>{`The table details the portfolio holdings in bull and bear regimes. During bear markets, we use the triple-leveraged inverse ETF to hedge away the exposure to U.S. stocks. We invest the remaining capital in a triple-leveraged Treasury ETF. We have only 20% of our capital left for this position. Consequently, we reach no more than 60% exposure to Treasury bonds.`}</p>
    <PortfolioChart portfolio="tt-all-stars-tax" mdxType="PortfolioChart" />
    <p>{`Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-four-fifteen/"
      }}>{`Four-Fifteen`}</a>{` portfolio implements this strategy as described. Because of the portfolio's constrained trading, the returns are less smooth than we would like them to be. We can significantly improve this by creating another `}<a parentName="p" {...{
        "href": "/articles/all-stars-portfolios/"
      }}>{`All-Stars Portfolio`}</a>{`. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{` portfolio nicely complements Four-Fifteen because it is tax-efficient and uses VIX futures for hedging purposes.`}</p>
    <PortfolioMetrics portfolio="tt-all-stars-tax" mdxType="PortfolioMetrics" />
    <p>{`As the equity chart shows, All-Stars Tax-Efficient performs admirably. The equity curve is smooth, and all risk metrics are lower than for a passive 60/40. At the same time, the portfolio creates a nice upside, continually beating the passive benchmark. In summary, All-Stars Tax-Efficient delivers strong returns in a tax-efficient package.`}</p>
    <ButtonPrimary text="Try All-Stars Tax-Efficient" to="/portfolios/tt-all-stars-tax/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      